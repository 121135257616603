import { Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';

let Wrapper = styled('div')`
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function NotFound() {
  return (
    <Wrapper>
      <div>
        <Text as="h1" size={28} weight={600}>
          404 - Not Found
        </Text>

        <Spacer height="6px" />

        <Text as="p" size={14} weight={500}>
          That's an error, but you probably know that.
        </Text>
      </div>
    </Wrapper>
  );
}
